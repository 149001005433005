import React from "react"

import Layout from "../components/Layout"
import { graphql } from 'gatsby'
import SEO from "../components/seo"
import { Link } from 'gatsby'
import StrapiContent from "../components/StrapiContent"
import StrapiShareLinks from "../components/StrapiShareLinks"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDown } from '@fortawesome/free-solid-svg-icons'
import moment from 'moment'

const BlogPost = ({data, location}) => {
    const post = data.strapiBlogPost
    let baseurl = `https://www.fbmud24.com`
    return (
        <Layout>
            <SEO title={post.title} meta={[
                { name: `og:url`,
                content: `${baseurl}${location.pathname}` },
                { name: `og:type`,
                content: `article` },
                { name: `og:title`,
                content: `${post.title}` },
                { name: `og:description`,
                content: `${post.description}` },
                { name: `og:image`,
                content: `${baseurl}${post.heroImage.localFile.childImageSharp.resize.src}` },
            ]}/>
            <section className="section">
            <div className="container content">
                    <div className="title-section post-title content-align">
                        <h1 className="title is-size-2 has-text-weight-bold is-bold-light">{post.title}</h1>
                        <small style={{display: 'block', marginTop: '-1rem'}}>Posted: {moment(post.publishedAt).format("MMMM D, YYYY")}</small>
                        <div className="dropdown is-hoverable" style={{marginTop: '2rem'}}>
                        <div className="dropdown-trigger">
                            <button className="button is-primary is-rounded" aria-haspopup="true" aria-controls="dropdown-menu4">
                            <span>Share</span>
                            <span className="icon is-small">
                                <FontAwesomeIcon icon={faAngleDown} />
                            </span>
                            </button>
                        </div>
                        <div className="dropdown-menu" id="dropdown-menu4" role="menu">
                            <div className="dropdown-content">
                            <StrapiShareLinks url={`${baseurl}${location.pathname}`} />
                            </div>
                        </div>
                        </div>
                        <Link style={{ marginLeft: '1rem', marginTop: '2rem'}} className="button is-dark is-rounded" to="/latest-updates">All Posts</Link>
                    </div>
                    <StrapiContent content={post.content} files={data.allFile} />
            </div>
            </section>
        </Layout>
    )
}

export default BlogPost

export const pageQuery = graphql`
    query StrapiBlogPostByID($id: String!, $localFiles: [String]!) {
        strapiBlogPost(id: {eq: $id}) {
            id
            title
            description
            publishedAt
            content
            heroImage {
                localFile {
                    childImageSharp {
                        resize {
                            src
                        }
                    }
                }
            }
        }
        allFile(filter: {id: {in: $localFiles}}) {
            nodes {
                id
                publicURL
                childImageSharp {
                    gatsbyImageData(
                        width: 1000
                        placeholder: TRACED_SVG
                        formats: [AUTO, WEBP, AVIF]
                    )
                }
            }
        }
    }
`


