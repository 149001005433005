import React from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"
import {GatsbyImage} from "gatsby-plugin-image"
import showdown from 'showdown'
import { endsWith } from "lodash"

const StrapiContent = ({ content, files }) => {
const converter = new showdown.Converter({
    strikethrough: true
})

    function getLocalNode(id) {
        return files.nodes.find((file) => {
            if(file.id === id) { return file }
        })
    }

return (
    <>  
        {content.map((ent, key) => {
            let imageNode = null
            let image = null
            switch(ent.strapi_component) {
                case "page.base-content":
                    let body = converter.makeHtml(ent.body)
                    return (<div className="content-align" style={{minHeight: ent.minHeight}} key={key} dangerouslySetInnerHTML={{__html: body}}></div>)
                    break;
                case "page.image":
                    imageNode = getLocalNode(ent.image.localFile___NODE)
                    image = imageNode.childImageSharp.gatsbyImageData
                    let classes = ``
                    let styles = {}
                    if(ent.placement === "banner") {
                        classes += "banner";
                    }else if(ent.placement === "wide") {
                        classes += "wide";
                    }else if(ent.placement === "left") {
                        styles = { float: 'left', marginRight: '1rem', maxWidth: ent.width ?? '50%' }
                    }else if(ent.placement === "right") {
                        styles = { float: 'right', marginLeft: '1rem', maxWidth: ent.width ?? '50%' }
                    } else {
                        styles = { maxWidth: ent.width ?? '' }
                    }
                    return <div className="content-align" key={key}><div style={styles} className={classes}><GatsbyImage image={image} alt="" /></div></div>
                    break
                case "page.link-card-with-image":
                    let cardbody = converter.makeHtml(ent.body)
                    imageNode = getLocalNode(ent.image.localFile___NODE)
                    image = imageNode.childImageSharp.gatsbyImageData
                    return (
                        <div className="content-align" key={key}>
                            <a className="box link-card" href={ent.url} target="_blank" rel="noopener noreferrer">
                                <div className="media">
                                    <div className="media-left">
                                        <figure className="link-card-image link-card-image-square">
                                            <GatsbyImage image={image} alt="" />
                                        </figure>
                                    </div>
                                    <div class="media-content" dangerouslySetInnerHTML={{__html: cardbody}}></div>
                                </div>
                            </a>
                        </div>
                    )
                    break
                case "page.split-content-image":
                    let splitbody = converter.makeHtml(ent.body)
                    imageNode = getLocalNode(ent.image.localFile___NODE)
                    image = imageNode.childImageSharp.gatsbyImageData
                    return (
                        <div className="split-w-image" key={key}>
                            <div className="split">
                                <div className="content-align"  dangerouslySetInnerHTML={{__html: splitbody}}></div>
                                    { ent.linkList.length !== 0 ? (
                                        <div className="content-align link-list">
                                            {ent.linkList.map(tlink => {
                                                if(tlink.type === "file") {
                                                    let file = getLocalNode(tlink.document.localFile___NODE)
                                                    return (<a key={tlink.id} href={file.publicURL} className="button is-primary">{tlink.title}</a>)
                                                } else if(tlink.type === "url") {
                                                    return (<a key={tlink.id} href={tlink.url} className="button is-primary">{tlink.title}</a>)
                                                }
                                            })}

                                        </div>
                                    ) : (<></>) }
                                    { ent.contentImages.length !== 0 ? (
                                    <div className="content-align image-grid">
                                        {ent?.contentImages.map((image) => {
                                            imageNode = getLocalNode(image.localFile___NODE)
                                            image = imageNode.childImageSharp.gatsbyImageData
                                                return (<div className="image-grid-item" key={image.id}><GatsbyImage image={image} alt="" /></div>)
                                        })}
                                    </div>
                                    ) : (<></>) }
                            </div>
                            <div className="split">
                                <div className="image-container">
                                    <GatsbyImage image={image} alt="" />
                                </div>
                            </div>
                        </div>
                    )
                    break
                case "page.gallery":
                    return (
                        <div class="gallery">
                            {ent.images.map(anImage => {
                                imageNode = getLocalNode(anImage.localFile___NODE)
                                image = imageNode.childImageSharp.gatsbyImageData
                                
                                return (
                                    <>
                                    { anImage.caption !== '' ? (
                                        <div key={anImage.id} className="gallery-item">
                                            <GatsbyImage image={image} alt="" />
                                            <div className="gallery-item-caption">
                                            <h4 className="is-size-3">{anImage.alternativeText}</h4>
                                            <p className="">{anImage.caption}</p>
                                            </div>
                                        </div>
                                    ) : (
                                        <div key={anImage.id} className="gallery-item no-caption">
                                            <GatsbyImage image={image} alt="" />
                                        </div>
                                    )}
                                    </>
                                )
                            })}
                        </div>
                    )
                    break
                case "page.link-list":
                    return (
                        <div className="content-align link-list">
                            {ent.link.map(tlink => {
                                if(tlink.type === "file") {
                                    let file = getLocalNode(tlink.document.localFile___NODE)
                                    return (<a key={tlink.id} href={file.publicURL} className="button is-primary">{tlink.title}</a>)
                                } else if(tlink.type === "url") {
                                    return (<a key={tlink.id} href={tlink.url} className="button is-primary">{tlink.title}</a>)
                                }
                            })}

                        </div>
                    )
                    break
                default:
                    return (<p key={key}>{key}</p>)
            }
        })}
    </>
)
}

export default StrapiContent